import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@scbt-shared/services/state.service';
import { accessTokenUrl } from '@scbt-lib/index';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { SclobyAuthService } from '../../scloby-auth/services/scloby-auth.service';

@Injectable({ providedIn: 'root' })
export class SclobyInterceptor implements HttpInterceptor {
	constructor(
		private sclobyLoginSrv: SclobyAuthService,
		private stateSrv: StateService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// requests to scloby only excluding auth request
		if (!request.url.includes('tilby.com') && !request.url.includes('scloby.com')) {
			return next.handle(request);
		}
		if (request.url.includes(accessTokenUrl)) {
			return next.handle(request);
		}

		return this.stateSrv.listenStoreId().pipe(
			take(1),
			switchMap((storeId) => this.sclobyLoginSrv.getTokenFromDb(storeId).pipe(take(1))),
			switchMap((tokenData) => {
				request = request.clone({
					setHeaders: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${tokenData.access_token}`,
					},
				});
				// console.log(request);
				return next.handle(request);
			})
		);
	}
}
