import { ApplicationConfig, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ServiceWorkerModule } from '@angular/service-worker';
import { storageGet } from '@scbt-lib/index';
import { IsAuthenticatedGuard } from '@scbt-shared/guards/isAuthenticated.guard';
import { provideViewConsole } from '@scbt-shared/view-console-feature/view-console.provider';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';

import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { MyHammerConfig } from '@scbt-shared/hammer.config';

// locale
import { registerLocaleData } from '@angular/common';
import localeItExtra from '@angular/common/locales/extra/it';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt, 'it', localeItExtra);

import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';
import 'moment/locale/it';

import { SCBTEAM_FIREBASE_CONFIG, SCBWEB_FIREBASE_CONFIG, environment } from '@scbt-env/environment';

import { HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { SclobyInterceptor } from './app/_shared/interceptors/scloby.interceptor';
import { TokenInterceptor } from './app/_shared/interceptors/token.interceptor';

// Firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { provideHttpClient } from '@angular/common/http';
import { provideRouter, withInMemoryScrolling, withPreloading, withViewTransitions } from '@angular/router';
import 'hammerjs';
import { InSequenceGuard } from './app/_shared/guards/in-sequence.guard';
import { PermissionsGuard } from './app/_shared/guards/permissions.guard';
import './app/_shared/prototypes';
import { InjectorService } from './app/_shared/services/injector.service';
import { AppPreloadingStrategy } from './app/app-preloading-strategy';
import { MatIconRegistry } from '@angular/material/icon';

const showConsole = storageGet('SHOW_DEV_CONSOLE') === 'true';
const SHOW_VIEW_CONSOLE_PRODIVER = showConsole ? [provideViewConsole()] : [];

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(
			appRoutes,
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
			// withDebugTracing()
			withPreloading(AppPreloadingStrategy),
			withViewTransitions()
			// withComponentInputBinding()
		),
		provideAnimationsAsync(),
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SclobyInterceptor,
			multi: true,
		},
		...SHOW_VIEW_CONSOLE_PRODIVER,
		{
			provide: `DOMAIN`,
			useValue: `${window.location.host.startsWith('localhost') ? 'http://' : 'https://'}${window.location.host}`,
			multi: false,
		},
		// importProvidersFrom([MomentDateModule]),
		provideMomentDateAdapter(),
		{ provide: MAT_DATE_LOCALE, useValue: 'it' },
		{ provide: LOCALE_ID, useValue: 'it' },
		provideFirebaseApp(() => initializeApp(SCBTEAM_FIREBASE_CONFIG)),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),
		provideMessaging(() => getMessaging()),
		provideStorage(() => getStorage()),
		provideFunctions(() => {
			//https://gist.github.com/rifayetuxbd/9a7dd11e31d76c7dc49b7919455632a6
			const functions = getFunctions();
			if (!environment.production) {
				connectFunctionsEmulator(functions, 'localhost', 6201);
			}
			return functions;
		}),
		provideFirebaseApp(() => initializeApp(SCBWEB_FIREBASE_CONFIG, 'scbWeb')),
		importProvidersFrom([
			NgxMaterialTimepickerModule.setOpts('it-IT', '24h'),
			ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

			HammerModule,
		]),
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig,
		},
		IsAuthenticatedGuard,
		PermissionsGuard,
		InSequenceGuard,
	],
};

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
	.then((appRef) => {
		InjectorService.injector = appRef.injector;
		const matIconRegistry = appRef.injector.get(MatIconRegistry);
		matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
		moment.locale('it');
	})
	.catch((err) => console.error(err));
