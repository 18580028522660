import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, timer, of as obsOf } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppPreloadingStrategy implements PreloadingStrategy {
	preload(route: Route, fn: () => Observable<unknown>): Observable<unknown> {
		const loadRoute = (delay: number) => (delay && delay > 0 ? timer(delay).pipe(mergeMap(() => fn())) : fn());

		return route.data?.preloadRoute ? loadRoute(route.data.preloadDelay ?? 0) : obsOf(null);
	}
}
