// To allow vertical scrolling when listening for swipes
// https://github.com/hammerjs/hammer.js/issues/1014
// <div
//   (swipeleft)="swipeClose()"
//   (swiperight)="swipeOpen()"
//   data-mc-options='{"touchAction": "pan-y"}'
// >
// </div>

import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { safeAny } from '@scb-lib/index';

declare let Hammer: safeAny;

@Injectable({ providedIn: 'root' })
export class MyHammerConfig extends HammerGestureConfig {
	//   overrides = <any>{
	//     'press': { time: 500, threshold: 20 } // override default settings
	//   };

	override buildHammer(element: HTMLElement) {
		let options = {};

		if (element.attributes['data-mc-options']) {
			try {
				const parseOptions = JSON.parse(element.attributes['data-mc-options'].nodeValue);
				options = parseOptions;
			} catch (err) {
				console.error('An error occurred when attempting to parse Hammer.js options: ', err);
			}
		}

		const mc = new Hammer(element, options);

		// keep default angular config
		mc.get('pinch').set({ enable: true });
		mc.get('rotate').set({ enable: true });

		// retain support for angular overrides object
		for (const eventName in this.overrides) {
			if (eventName) {
				mc.get(eventName).set(this.overrides[eventName]);
			}
		}

		return mc;
	}
}
