import { AsyncPipe } from '@angular/common';
import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterOutlet } from '@angular/router';
import { A2hsService } from '@scbt-shared/services/a2hs.service';
import { AuthService } from '@scbt-shared/services/auth.service';
import { StateService } from '@scbt-shared/services/state.service';
import { SWService } from '@scbt-shared/services/sw.service';
import { TitleService } from '@scbt-shared/services/title.service';
import { UIService } from '@scbt-shared/services/ui.service';
import { environment } from '@scbt-env/environment';
import { BUILD_TYPES } from '@scbt-lib/index';
import { fadeInOut } from '@scbt-shared/anim/animations';
import { combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import semverGt from 'semver/functions/gt';
import { safeAny } from '@scb-lib/index';
import { LoadingComponent } from './_shared/cmp/loading/loading.component';
import { SclobySyncPanelComponent } from './_shared/cmp/scloby-sync-panel/scloby-sync-panel.component';
import { ToastMessageComponent } from './_shared/cmp/toast-message/toast-message.component';
import { ViewConsoleComponent } from './_shared/view-console-feature/view-console/view-console.component';
import { SidenavComponent } from './cmp/sidenav/sidenav.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [
    MatSidenavModule,
    ToastMessageComponent,
    SclobySyncPanelComponent,
    ViewConsoleComponent,
    RouterOutlet,
    SidenavComponent,
    LoadingComponent,
    AsyncPipe
],
	animations: [fadeInOut()],
})
export class AppComponent implements OnInit, AfterContentInit {
	@ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
	newVerWarning = false;

	dbVer: string;
	environment = environment;
	background$ = this.UISrv.backgroundTaskRunning().pipe(map((v) => v > 0));
	loaderVisible$ = this.UISrv.loaderOvVisible();

	constructor(
		public authSrv: AuthService,
		private snackBar: MatSnackBar,
		private titleSrv: TitleService,
		private swSrv: SWService,
		private a2hsService: A2hsService,
		public UISrv: UIService,
		private stateSrv: StateService
	) {
		this.worker_update();

		// this.user$ = this.authSrv.user$;
		// this.loaded$ = this.authSrv.user$.pipe(map(user => user === undefined ? false : true));
	}

	ngOnInit() {
		this.UISrv.setSidenav(this.sidenav);

		// this.swSrv.getMessages().subscribe(msg => {
		// });

		this.stateSrv
			.listenAppVersionFromDb()
			.pipe(
				filter((d) => !!d),
				tap((dbVer) => {
					this.dbVer = dbVer;
					if (environment.buildType === BUILD_TYPES.prod && semverGt(dbVer, environment.version)) {
						this.newVerWarning = true;
					} else {
						this.newVerWarning = false;
					}
				})
			)
			.subscribe();
	}

	ngAfterContentInit() {
		// deferred after app loaded
		setTimeout(() => {
			this.titleSrv.startTitleChange();
			this.a2hsService.startListenCanInstall();
			this.a2hs_CheckIfDisplayPrompt();

			// Listen to tab events to enable outlines (accessibility improvement)
			document.body.addEventListener(
				'keyup',
				(e) => {
					let isTab: boolean;
					if (e.key !== undefined) {
						isTab = e.key === 'Tab';
					} else {
						isTab = (e.which || e.charCode || e.keyCode || 0) === 9;
					}
					if (isTab) {
						document.documentElement.classList.remove('no-focus-outline');
					}
				},
				false
			);
		});
	}

	worker_update() {
		this.swSrv.swUpdateAvailable$.pipe(filter((evt) => !!evt)).subscribe((event) => {
			const appData = event.latestVersion.appData as safeAny;
			console.log('Update avaliable:', appData?.version);
			const snackBarRef = this.snackBar.open(`E' disponibile nuova versione (${appData.version})`, 'RICARICA');
			snackBarRef.onAction().subscribe(() => {
				window.location.reload();
				location.reload();
			});
		});
	}
	a2hs_CheckIfDisplayPrompt() {
		combineLatest([this.authSrv.user$, this.a2hsService.canInstall()])
			.pipe(
				tap(([user, canInstall]) => {
					if (user && canInstall) {
						const snackBarRef = this.snackBar.open(`Vuoi aggiungere ${environment.appName} alla Home?`, 'AGGIUNGI');
						snackBarRef.onAction().subscribe(() => {
							this.a2hsService.displayPrompt();
						});
					}
				})
			)
			.subscribe();
	}
}
