import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { importProvidersFrom } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { PERMISSIONS } from '@scbt-lib/index';
import { PageNotFoundComponent } from '@scbt-shared/cmp/page-not-found/page-not-found.component';
import { InSequenceGuard } from '@scbt-shared/guards/in-sequence.guard';
import { IsAuthenticatedGuard } from '@scbt-shared/guards/isAuthenticated.guard';
import { PermissionsGuard } from '@scbt-shared/guards/permissions.guard';

const indexRoute: Route = {
	path: '',
	redirectTo: '/home',
	pathMatch: 'full',
};
// Page not found
const fallbackRoute: Route = {
	path: '**',
	component: PageNotFoundComponent,
};

export const appRoutes: Routes = [
	{
		path: 'home',
		loadComponent: () => import('./cmp/home/home.component').then((m) => m.HomeComponent),
		data: {
			preloadRoute: false,
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.crmModule],
		},
	},
	{
		path: 'crm',
		loadChildren: () => import('./crm/crm.routes').then((m) => m.CRM_ROUTES),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.crmModule],
		},
	},
	{
		path: 'activities',
		loadChildren: () => import('./activities/activities.routes').then((m) => m.activitiesRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.activitiesModule],
		},
	},
	{
		path: 'timecards',
		loadChildren: () => import('./timecards/timecards.routes').then((m) => m.timecardsRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.punchesModule],
		},
	},
	{
		path: 'self-consumption',
		loadChildren: () => import('./self-consumption/self-consumption.routes').then((m) => m.selfConsumptionRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.selfConsumptionModule],
		},
	},
	{
		path: 'products',
		loadChildren: () => import('./products/products.routes').then((m) => m.productsRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.productsModule],
		},
	},
	{
		path: 'sales',
		loadChildren: () => import('./sales/sales.routes').then((m) => m.salesRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.salesModule],
		},
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.routes').then((m) => m.authRoutes),
		providers: [importProvidersFrom([MatPasswordStrengthModule.forRoot()])],
		data: { preloadRoute: false, preloadDelay: 1200 },
	},
	{
		path: 'users',
		loadChildren: () => import('./users/users.routes').then((m) => m.usersRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.usersModule],
		},
	},
	{
		path: 'scloby',
		loadChildren: () => import('./scloby-auth/scloby-auth.routes').then((m) => m.sclobyAuthRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.admin],
		},
	},
	{
		path: 'counter',
		loadChildren: () => import('./counter/counter.routes').then((m) => m.counterRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.counterModule],
		},
	},
	{
		path: 'gmb-auth',
		loadChildren: () => import('./gmb-auth/gmb-auth.routes').then((m) => m.gmbAuthRoutes),
		canActivate: [IsAuthenticatedGuard, PermissionsGuard],
		data: {
			permissions: [PERMISSIONS.gmbModule],
		},
	},
	{
		path: 'menus',
		loadChildren: () => import('./menus/menus.routes').then((m) => m.menusRoutes),
		canActivate: [IsAuthenticatedGuard, PermissionsGuard],
		data: {
			permissions: [PERMISSIONS.menusModule],
		},
	},
	{
		path: 'gmb',
		loadChildren: () => import('./gmb/gmb.routes').then((m) => m.gmbRoutes),
		canActivate: [IsAuthenticatedGuard, PermissionsGuard],
		data: {
			permissions: [PERMISSIONS.gmbModule],
		},
	},
	{
		path: 'bookings',
		loadChildren: () => import('./bookings/bookings.routes').then((m) => m.bookingsRoutes),
		canActivate: [IsAuthenticatedGuard, PermissionsGuard],
		data: {
			permissions: [PERMISSIONS.bookingsModule],
		},
	},
	{
		path: 'coupons',
		loadChildren: () => import('./coupons/coupons.routes').then((m) => m.couponsRoutes),
		canActivate: [IsAuthenticatedGuard, PermissionsGuard],
		data: {
			permissions: [PERMISSIONS.couponsEditor, PERMISSIONS.couponsRedeemer, PERMISSIONS.couponsRedeemerSuper],
		},
	},
	{
		path: 'admin-utils',
		loadChildren: () => import('./admin-utils/admin-utils.routes').then((m) => m.adminUtilsRoutes),
		canActivate: [InSequenceGuard],
		data: {
			inSequenceGuard: [IsAuthenticatedGuard, PermissionsGuard],
			permissions: [PERMISSIONS.admin],
		},
	},

	indexRoute,
	fallbackRoute,
];
