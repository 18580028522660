/* eslint-disable prefer-rest-params */
import { Injectable } from '@angular/core';
import { ViewConsoleDispatcher } from './view-console-action-dispatcher';

@Injectable({
	providedIn: 'root',
})
export class ViewConsoleMonkeyPatcher {
	patchConsole() {
		const log = console.log;
		const clear = console.clear;

		console.log = function () {
			ViewConsoleDispatcher.log(arguments);
			log.apply(console, arguments as any);
		};

		console.clear = function () {
			ViewConsoleDispatcher.reset();
			clear.apply(console, arguments as any);
		};
	}
}
