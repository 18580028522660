import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class A2hsService {

    private deferredPrompt: any;
    private a2HScanInstall$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() {
    }

    startListenCanInstall() {

        window.addEventListener('beforeinstallprompt', (e) => {
            console.log('A2HS user can install');
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            this.a2HScanInstall$.next(true);
        });

    }

    canInstall(): Observable<boolean> {
        return this.a2HScanInstall$.asObservable();
    }

    displayPrompt() {
        if (!this.deferredPrompt) { return; }
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice
            .then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');

                    const installedListener = (e: Event) => {
                        console.log('A2HS installed');
                        window.removeEventListener('appinstalled', installedListener);
                    };
                    window.addEventListener('appinstalled', installedListener);

                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                this.deferredPrompt = null;
                this.a2HScanInstall$.next(false);
            });
    }




}


/*

//css
@media all and (display-mode: standalone) {
  body {
    background-color: yellow;
  }
}

// javascript
if (window.matchMedia('(display-mode: standalone)').matches) {
  console.log('display-mode is standalone');
}

// safari
if (window.navigator.standalone === true) {
  console.log('display-mode is standalone');
}


*/
