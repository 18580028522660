<div *appLet="user$ | async as user" class="d-flex flex-column h-100 align-items-start">
	<div id="userpanel" class="w-100">
		<mat-list>
			@if (user) {
				<mat-list-item>
					<div class="d-flex justify-content-start gap-2">
						<app-avatar class="d-block" [size]="'large'" [initials]="false"></app-avatar>
						<div>
							<h5 class="m_t_c_primary_inverse" [style.margin-bottom]="0">{{ user.fb.displayName }}</h5>
							<span class="d-block m_t_c_secondary_inverse">{{ user.fb.email }}</span>
						</div>
					</div>
				</mat-list-item>
			}
			@if (user === null) {
				<mat-list-item>
					<button class="d-block mx-auto" (click)="sidenav.close(); router.navigate(['/auth/login'])" mat-button color="accent">
						ACCEDI
					</button>
				</mat-list-item>
			}
		</mat-list>

		@if (user) {
			<app-store-select class="d-block px-3 w-100 angular-material-inverted-colors-on-primary-background"> </app-store-select>
		}
	</div>

	<ng-template
		#stdLine
		let-text="text"
		let-iconName="iconName"
		let-showBadge="showBadge"
		let-badgeNumber="badgeNumber"
		let-badgeColor="badgeColor">
		<div class="w-100 d-flex justify-content-start align-items-center m_pointer">
			@if (showBadge) {
				<mat-icon
					class="d-block list_icons_settings"
					[matBadge]="badgeNumber"
					[matBadgeColor]="badgeColor ? badgeColor : 'warn'"
					matBadgeSize="small"
					>{{ iconName }}</mat-icon
				>
			} @else {
				<mat-icon class="d-block list_icons_settings">{{ iconName }}</mat-icon>
			}
			<div>{{ text }}</div>
		</div>
	</ng-template>

	@if (user) {
		<mat-list class="d-block w-100">
			<mat-list-item (click)="handleClick('/')">
				<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Home', iconName: 'home' }"></ng-container>
			</mat-list-item>
			<!-- Personel -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.personelModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Personale', iconName: 'chrome_reader_mode' }
									"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list class="d-block w-100">
							@if (authSrv.listenCheckPermissions([PERMISSIONS.punchesModule]) | async) {
								<mat-list-item (click)="handleClick('/timecards/punch-add')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Aggiungi timbratura', iconName: 'chrome_reader_mode' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.punchesModule]) | async) {
								<mat-list-item (click)="handleClick('/timecards/punch-check')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Controllo timbrature', iconName: 'chrome_reader_mode' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.punchesSummary]) | async) {
								<mat-list-item (click)="handleClick('/timecards/punch-summary')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Sommario timbrature', iconName: 'chrome_reader_mode' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.storesViewer]) | async) {
								<mat-list-item (click)="handleClick('/timecards/stores-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Negozi', iconName: 'store_mall_directory' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.resourcesViewer]) | async) {
								<mat-list-item (click)="handleClick('/timecards/resources-list')">
									<ng-container
										*ngTemplateOutlet="stdLine; context: { text: 'Risorse', iconName: 'accessibility' }"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.selfConsumptionModule]) | async) {
								<mat-list-item (click)="handleClick('/self-consumption/self-consumption-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Auto Consumo', iconName: 'assignment' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.selfConsumptionConfigEditor]) | async) {
								<mat-list-item (click)="handleClick('/self-consumption/self-consumption-config')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Auto Consumo - Configurazione', iconName: 'assignment_late' }
										"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Products 1 -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.productsModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Prodotti', iconName: 'layers' }"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list class="d-block w-100">
							<mat-list-item (click)="handleClick('/products/items')">
								<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Prodotti', iconName: 'layers' }"></ng-container>
							</mat-list-item>
							<mat-list-item (click)="handleClick('/products/items-price')">
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Prodotti listino', iconName: 'euro_symbol' }
									"></ng-container>
							</mat-list-item>
							<mat-list-item (click)="handleClick('/products/materials')">
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Materie prime', iconName: 'gavel' }"></ng-container>
							</mat-list-item>
							<mat-list-item (click)="handleClick('/products/mat-cats')">
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Categorie Materiali', iconName: 'toc' }"></ng-container>
							</mat-list-item>
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Products 2-->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.productsModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Magazzino e fornitori', iconName: 'widgets' }
									"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list class="d-block w-100">
							<mat-list-item (click)="handleClick('/products/stock')">
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Giacenze', iconName: 'content_paste' }"></ng-container>
							</mat-list-item>
							<mat-list-item (click)="handleClick('/products/movements')">
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Movimenti passati', iconName: 'import_export' }
									"></ng-container>
							</mat-list-item>
							<mat-list-item (click)="handleClick('/products/mov-add')">
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Aggiungi movimento', iconName: 'import_export' }
									"></ng-container>
							</mat-list-item>
							<mat-divider></mat-divider>
							@if (authSrv.listenCheckPermissions([PERMISSIONS.productsModule]) | async) {
								<mat-list-item (click)="handleClick('/products/articles-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Articoli fornitori', iconName: 'article' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.admin]) | async) {
								<mat-list-item (click)="handleClick('/products/articles-match')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Match articoli', iconName: 'article' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.admin]) | async) {
								<mat-list-item (click)="handleClick('/products/articles-upload')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Carica articoli su DB', iconName: 'article' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.admin]) | async) {
								<mat-list-item (click)="handleClick('/products/articles-movements-upload')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Carica acquisti articoli', iconName: 'article' }
										"></ng-container>
								</mat-list-item>
							}
							<mat-divider></mat-divider>
							<mat-list-item (click)="handleClick('/products/stock-consumptions')">
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Consumi', iconName: 'fact_check' }"></ng-container>
							</mat-list-item>
							<mat-divider></mat-divider>
							@if (authSrv.listenCheckPermissions([PERMISSIONS.inventoryViewer]) | async) {
								<mat-list-item (click)="handleClick('/products/inventory-list')">
									<ng-container
										*ngTemplateOutlet="stdLine; context: { text: 'Inventario', iconName: 'inventory' }"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Crm -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.menusModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Menù', iconName: 'newspaper' }"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							@if (authSrv.listenCheckPermissions([[PERMISSIONS.menusViewer, PERMISSIONS.menusEditor]]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/menus/menus-config-edit')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Configurazione Menus', iconName: 'settings' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([[PERMISSIONS.menusViewer, PERMISSIONS.menusEditor]]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/menus/menus-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Elenco Menus', iconName: 'newspaper' }
										"></ng-container>
								</mat-list-item>
							}
							@if (
								authSrv.listenCheckPermissions([[PERMISSIONS.menusProductViewer, PERMISSIONS.menusProductEditor]]) | async
							) {
								<mat-list-item class="m_pointer" (click)="handleClick('/menus/products-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Elenco Prodotti', iconName: 'newspaper' }
										"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Counter -->
			@if (
				(authSrv.listenCheckPermissions([PERMISSIONS.counterClosingViewer]) | async) ||
				(authSrv.listenCheckPermissions([PERMISSIONS.counterClosingEditor]) | async)
			) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Cassa e cassetto', iconName: 'store_mall_directory' }
									"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							@if (authSrv.listenCheckPermissions([PERMISSIONS.counterClosingEditor]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/counter/counter-closing')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Chiudi cassa', iconName: 'account_balance' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.counterClosingViewer]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/counter/counter-closing-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Chiusure cassa', iconName: 'account_balance' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.counterClosingViewer]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/counter/cash-movements-by-day-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Prima nota per giorno', iconName: 'show_chart' }
										"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([PERMISSIONS.counterClosingViewer]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/counter/cash-movements-list')">
									<ng-container
										*ngTemplateOutlet="stdLine; context: { text: 'Prima nota', iconName: 'show_chart' }"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Sales -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.salesModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Vendite', iconName: 'store_mall_directory' }
									"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list class="d-block w-100">
							<mat-list-item (click)="handleClick('/sales/sales-list')">
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Vendite', iconName: 'store_mall_directory' }
									"></ng-container>
							</mat-list-item>
							<mat-list-item (click)="handleClick('/sales/sales-daily-payments')">
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: { text: 'Vendite giornaliere', iconName: 'store_mall_directory' }
									"></ng-container>
							</mat-list-item>
							<mat-list-item (click)="handleClick('/sales/orders-list')">
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Comande', iconName: 'event_note' }"></ng-container>
							</mat-list-item>
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Activities -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.activitiesModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Attività', iconName: 'local_activity' }"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							@if (
								authSrv.listenCheckPermissions([PERMISSIONS.activitiesViewer, PERMISSIONS.activitiesEditor], 'OR') | async
							) {
								<mat-list-item class="m_pointer" (click)="handleClick('/activities/activities-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Elenco Attività', iconName: 'local_activity' }
										"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Crm -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.crmModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container *ngTemplateOutlet="stdLine; context: { text: 'CRM', iconName: 'group' }"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							@if (authSrv.listenCheckPermissions([[PERMISSIONS.contactsViewer, PERMISSIONS.contactsEditor]]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/crm/contacts-list')">
									<ng-container
										*ngTemplateOutlet="stdLine; context: { text: 'Lista Contatti', iconName: 'group' }"></ng-container>
								</mat-list-item>
							}
							@if (authSrv.listenCheckPermissions([[PERMISSIONS.contactsEditor]]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/crm/contacts-upload')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Carica Contatti', iconName: 'group_add' }
										"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Bookings -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.bookingsModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion" *appLet="bookingsRequiringAnswer() as bookings">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="
										stdLine;
										context: {
											text: 'Prenotazioni',
											iconName: 'event',
											showBadge: bookings.length > 0,
											badgeNumber: bookings.length,
										}
									"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							@if (authSrv.listenCheckPermissions([PERMISSIONS.bookingsModule]) | async) {
								<mat-list-item class="m_pointer" (click)="handleClick('/bookings/bookings-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: {
												text: 'Prenotazioni',
												iconName: 'event',
												showBadge: bookings.length > 0,
												badgeNumber: bookings.length,
											}
										"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Coupons -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.couponsModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Coupons', iconName: 'card_membership' }"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							@if (
								authSrv.listenCheckPermissions([[PERMISSIONS.couponsCampaignsViewer, PERMISSIONS.couponsCampaignsEditor]])
									| async
							) {
								<mat-list-item class="m_pointer" (click)="handleClick('/coupons/campaigns-list')">
									<ng-container
										*ngTemplateOutlet="
											stdLine;
											context: { text: 'Campagne', iconName: 'card_membership' }
										"></ng-container>
								</mat-list-item>
							}
						</mat-list>
						<mat-list>
							@if (
								authSrv.listenCheckPermissions(
									[PERMISSIONS.couponsEditor, PERMISSIONS.couponsRedeemer, PERMISSIONS.couponsRedeemerSuper],
									'OR'
								) | async
							) {
								<mat-list-item class="m_pointer" (click)="handleClick('/coupons/coupon-redeem')">
									<ng-container
										*ngTemplateOutlet="stdLine; context: { text: 'Redimi coupon', iconName: 'redeem' }"></ng-container>
								</mat-list-item>
							}
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Users -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.usersModule]) | async) {
				<mat-divider></mat-divider>
				<mat-list-item class="m_pointer" (click)="handleClick('/users/users-list')">
					<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Utenti', iconName: 'people' }"></ng-container>
				</mat-list-item>
			}
			<!-- Scloby Login -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.admin]) | async) {
				<mat-divider></mat-divider>
				<mat-list-item class="m_pointer" (click)="handleClick('/scloby/scloby-login')">
					<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Scloby Login', iconName: 'login' }"></ng-container>
				</mat-list-item>
			}
			<!-- Gmb Module -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.gmbModule]) | async) {
				<mat-divider></mat-divider>
				<mat-accordion>
					<mat-expansion-panel id="expansion">
						<mat-expansion-panel-header>
							<mat-panel-title>
								<ng-container
									*ngTemplateOutlet="stdLine; context: { text: 'Google My Business', iconName: 'place' }"></ng-container>
							</mat-panel-title>
						</mat-expansion-panel-header>
						<mat-list>
							<mat-list-item class="m_pointer" (click)="handleClick('/gmb-auth/gmb-login')">
								<ng-container *ngTemplateOutlet="stdLine; context: { text: 'GMB Login', iconName: 'login' }"></ng-container>
							</mat-list-item>
							<mat-list-item class="m_pointer" (click)="handleClick('/gmb/gmb-sync')">
								<ng-container *ngTemplateOutlet="stdLine; context: { text: 'GMB Sync', iconName: 'place' }"></ng-container>
							</mat-list-item>
						</mat-list>
					</mat-expansion-panel>
				</mat-accordion>
			}
			<!-- Admin Utils -->
			@if (authSrv.listenCheckPermissions([PERMISSIONS.admin]) | async) {
				<mat-divider></mat-divider>
				<mat-list-item class="m_pointer" (click)="handleClick('/admin-utils/scloby-utils')">
					<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Utils', iconName: 'settings' }"></ng-container>
				</mat-list-item>
			}
			<!-- Show console -->
			<ng-container>
				<mat-divider></mat-divider>
				<mat-list-item class="m_pointer" (click)="UISrv.toggleDevConsoleVisbility()">
					<div class="w-100 d-flex justify-content-start align-items-center">
						<mat-icon class="d-block list_icons_settings">code</mat-icon>
						<mat-slide-toggle [checked]="UISrv.showDevConsole$ | async"> Console sviluppatore </mat-slide-toggle>
					</div>
				</mat-list-item>
			</ng-container>
			<!-- Change Pass -->
			<ng-container>
				<mat-divider></mat-divider>
				<mat-list-item class="m_pointer" (click)="handleClick('/auth/change-password')">
					<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Cambia password', iconName: 'lock' }"></ng-container>
				</mat-list-item>
			</ng-container>
			<!-- Log Out -->
			<ng-container>
				<mat-divider></mat-divider>
				<mat-list-item class="m_pointer" (click)="logout()">
					<ng-container *ngTemplateOutlet="stdLine; context: { text: 'Esci', iconName: 'exit_to_app' }"></ng-container>
				</mat-list-item>
			</ng-container>
		</mat-list>
	}

	<div class="mb-auto"></div>

	<div class="w-100 p-2">
		<div class="m_t_hint">Versione: {{ version }}</div>
		<div class="m_t_hint">[ SW {{ swInstalled$ | async }} | {{ swActivated$ | async }} ]</div>
	</div>
</div>
