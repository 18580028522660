@defer (on timer(500ms)) {
	@if (authSrv.user$ | async) {
		<app-scloby-sync-panel></app-scloby-sync-panel>
	}
} @error {
	Errore
}
@defer (on timer(1000ms)) {
	@if (UISrv.showDevConsole$ | async) {
		<app-view-console></app-view-console>
	}
}

<mat-sidenav-container>
	<mat-sidenav #sidenav [fixedInViewport]="true" mode="over" opened="false">
		<app-sidenav [sidenav]="sidenav"></app-sidenav>
	</mat-sidenav>

	<mat-sidenav-content>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>

<ng-template #loadingHome>
	<div id="centerInViewWToolbar">
		<app-loading class="mainLoadingSpinner" color="accent"> </app-loading>
	</div>
</ng-template>

<ng-template #loadingSidenav>
	<app-loading class="sidebarSpinner" color="white"></app-loading>
</ng-template>

@if (newVerWarning) {
	<div id="newVerWarning" class="m_t_paragraph_big m_t_bold">
		E' disponibile una nuova versione (Nuova: {{ dbVer }} Attuale: {{ environment.version }}).
		<br />
		<br />
		Ricarica la pagina e/o attendi il messsaggio per installare la nuova versione.
		<br />
		<br />
		NON continuare ad usare l'app, prima di aver aggiornato.
	</div>
}

@if (background$ | async) {
	<app-loading class="backgroundTaskSpinner" color="accent"> </app-loading>
}

@if (loaderVisible$ | async) {
	<div class="loaderOverlay" [@fadeInOut]="{ params: { durationIn: '10', durationOut: '100' } }">
		<app-loading color="accent"></app-loading>
	</div>
}

@defer (on timer(1ms)) {
	<app-toast-message id="toastContainer"></app-toast-message>
}
