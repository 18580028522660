interface Number {
	maxDecimals(numDecimalDigits?: number): number;
}

if (!Number.prototype.maxDecimals) {
	Number.prototype.maxDecimals = function (numDecimalDigits = 10): number {
		if (!this) {
			return this;
		}
		const num = parseFloat(this.toFixed(numDecimalDigits));
		if (Number.isNaN(num)) {
			console.error(num);
		}
		return num;
	};
}
