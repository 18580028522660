import { ENVIRONMENT_INITIALIZER, inject } from '@angular/core';

import { ViewConsoleMonkeyPatcher } from './view-console-patcher.service';

export const provideViewConsole = () => ({
	provide: ENVIRONMENT_INITIALIZER,
	multi: true,
	useValue() {
		inject(ViewConsoleMonkeyPatcher).patchConsole();
	},
});
