import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatList, MatListItem } from '@angular/material/list';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { environment } from '@scbt-env/environment';
import { IUser, PERMISSIONS } from '@scbt-lib/index';
import { AuthService } from '@scbt-shared/services/auth.service';
import { SWService } from '@scbt-shared/services/sw.service';
import 'firebase/compat/auth';
import { Observable, Subject } from 'rxjs';
import { AvatarComponent } from '../../_shared/cmp/avatar/avatar.component';
import { StoreSelectComponent } from '../../_shared/cmp/store-select/store-select.component';
import { LetDirective } from '../../_shared/directives/let.directive';
import { UIService } from '../../_shared/services/ui.service';
import { BookingsService } from 'src/app/bookings/bookings.service';
import { MatIcon } from '@angular/material/icon';
import { MatBadge } from '@angular/material/badge';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		LetDirective,
		MatList,
		MatListItem,
		AvatarComponent,
		MatButton,
		StoreSelectComponent,
		MatDivider,
		MatAccordion,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		MatSlideToggle,
		AsyncPipe,
		NgTemplateOutlet,
		MatIcon,
		MatBadge,
	],
})
export class SidenavComponent implements OnInit, OnDestroy {
	private onDestroy$: Subject<void> = new Subject();

	@Input() sidenav: MatSidenav;
	version: string = environment.version;

	user$: Observable<IUser>;

	swInstalled$: Observable<string>;
	swActivated$: Observable<string>;
	PERMISSIONS = PERMISSIONS;

	bookingsRequiringAnswer = toSignal(this.bookingSrv.listenBookingsRequiringAnswer(), { initialValue: [] });

	constructor(
		private swService: SWService,
		public router: Router,
		public authSrv: AuthService,
		public UISrv: UIService,
		public bookingSrv: BookingsService
	) {
		this.user$ = this.authSrv.user$;
	}

	ngOnInit() {
		this.swInstalled$ = this.swService.swInstalledVersion$;
		this.swActivated$ = this.swService.swActivatedVersion$;
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	handleClick(url: string) {
		this.sidenav.close();
		this.router.navigate([url]);
	}

	logout() {
		this.sidenav.close();
		this.authSrv.logout();
		this.router.navigate(['/home']);
	}
}
