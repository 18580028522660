import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@scbt-env/environment';
import { Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TitleService {
	stopTitleChange$: Subject<void> = new Subject();

	constructor(
		private router: Router,
		private titleService: Title
	) {}

	startTitleChange() {
		console.log('startTitleChange');

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.stopTitleChange$),
				startWith(null),
				map(() => {
					let child = this.router.routerState.snapshot.root;
					let title: string = child.data.title;
					while (child.firstChild) {
						child = child.firstChild;
						if (child.data.title) {
							title = child.data.title;
						}
					}
					return title;
				})
			)
			.subscribe((title: string | undefined | null) => {
				if (title) {
					this.titleService.setTitle(title + ' - ' + environment.appName);
				} else {
					this.titleService.setTitle(environment.appName);
				}
			});
	}

	stopTitleChange() {
		console.log('stopTitleChange');
		this.stopTitleChange$.next();
	}
}
